<template>
  <v-flex xs12 lg3 sm3 md3>
    <v-card v-sticky="shouldStick" sticky-offset="offset" sticky-side="both" sticky-z-index="Zindex">
      <v-card-text class="text-xs-center">
        <div class="mr-2 mb-3">
          <AvatarLetter :size="54" :text-class="'headline'" :text="buyer.name" color="secondary" />
        </div>
        <div class="headline font-weight-thin mb-2" v-html="buyer.name"></div>
        <p class="title font-weight-regular body-1" v-text="buyer.email"></p>
        <CustomerBuy
          add-class="text-xs-center px-2 py-2 mb-2"
          :last-purchase="buyer.last_purchase"
          :orders-last-year="buyer.orders_last_year"
          :type="0"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="success" :to="{ name: 'CheckoutUserID', params: { userID: buyer.id } }" icon>
          <v-icon small>fas fa-shopping-basket</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed flat icon @click="dialogCoupon = true">
          <v-icon>card_giftcard</v-icon>
        </v-btn>
        <v-btn v-if="buyer.email !== ''" :href="`mailto:${buyer.email}`" depressed flat icon>
          <v-icon>mail</v-icon>
        </v-btn>
        <v-btn v-if="buyer.phone !== ''" :href="`tel:${buyer.phone}`" depressed flat icon>
          <v-icon>phone_forwarded</v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider></v-divider>
      <v-list dense class="ma-0 pa-0" style="background:transparent;">
        <template v-for="(item, i) in items">
          <v-layout v-if="item.heading" :key="i" row align-center>
            <v-flex xs12>
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-flex>
          </v-layout>
          <v-divider v-else-if="item.divider" :key="i" dark class="my-3"></v-divider>
          <v-list-tile v-else :key="i" @click="goRoute(item)">
            <v-list-tile-action class="mr-0 pl-0">
              <v-icon class="ml-2">{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title class="grey--text">
                {{ item.text }}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider v-if="i + 1 !== items.length" :key="`div-${i}`" inset></v-divider>
        </template>
      </v-list>
    </v-card>

    <v-dialog v-model="dialogCoupon" width="300" persistent>
      <v-card>
        <TitleDialog
          :title="'Crear cupon express'"
          :subtitle="'Ajuste los parametros'"
          close
          :close-action="
            () => {
              closeCupon()
            }
          "
        >
        </TitleDialog>
        <v-layout v-if="!generateCupon" wrap>
          <v-flex xs12 class="pa-0">
            <v-subheader>Tipo de descuento</v-subheader>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap class="py-2 px-3">
              <v-flex xs12>
                <v-radio-group v-model="cupon.type" row>
                  <v-radio label="Porcentaje" :value="1"></v-radio>
                  <v-radio label="Monto" :value="2"></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap class="py-2 px-3">
              <v-flex xs12 class="mb-2">
                <v-text-field
                  v-model="cupon.value"
                  :prepend-icon="cupon.type === 1 ? 'fas fa-percentage' : 'fas fa-dollar-sign'"
                  label="Descuento"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 class="mb-2">
                <v-text-field v-model="cupon.min" prepend-icon="fas fa-dollar-sign" label="Monto minimo"></v-text-field>
              </v-flex>
              <v-flex xs12 class="mb-2">
                <v-text-field
                  v-model="cupon.expire"
                  prepend-icon="event"
                  label="Fecha expiracion"
                  type="date"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-card-text v-else>
          Email y whatsapp, enviado al cliente <br />
          El codigo es: <strong>{{ cuponCode }}</strong>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat color="back" depressed @click="closeCupon">
            Volver
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="!generateCupon" flat color="accent" depressed @click="saveCoupon">
            Listo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import { COUPON_GENERATE } from '../../config'
import AvatarLetter from '../general/avatarLetter.vue'
import TitleDialog from '../useful/titleDialog.vue'
import CustomerBuy from '../general/customerBuy.vue'

export default {
  name: 'BuyerNav',
  components: { AvatarLetter, TitleDialog, CustomerBuy },
  props: ['goRoute', 'buyer', 'getBuyer'],
  data() {
    return {
      dialogCoupon: false,
      generateCupon: false,
      cuponCode: null,
      cupon: {
        value: '15',
        type: 1,
        min: '25000',
        expire: this.$moment()
          .add(10, 'months')
          .format('YYYY-MM-DD')
      },
      items: [
        { icon: 'home', text: 'Datos personales', route: 'buyerDetail' },
        { icon: 'pin_drop', text: 'Direcciones', route: 'buyerAddress' },
        // { icon: 'domain', text: 'Facturacion', route: 'buyerSii' },
        { icon: 'shopping_basket', text: 'Pedido', route: 'buyerOrders' },
        {
          icon: 'date_range',
          text: 'Suscripciones',
          route: 'buyerSuscriptions'
        },
        { icon: 'card_giftcard', text: 'Cupones', route: 'buyerCoupons' },
        // { icon: 'poll', text: 'Encuestas', route: 'buyerPolls' },
        { icon: 'whatshot', text: 'Reclamos', route: 'buyerClaims' },
        {
          icon: 'date_range',
          text: 'Recordatorio de fechas',
          route: 'buyerDates'
        }
        // { icon: 'fab fa-whatsapp', text: 'WhatsApp', route: 'buyerWhatsApp' },
      ]
    }
  },
  computed: {
    shouldStick() {
      return this.$mq !== 'xs'
    },
    comments() {
      return this.$store.getters.getComments
    },
    states() {
      return this.$store.getters.getStates
    }
  },
  mounted() {},
  methods: {
    async saveCoupon() {
      try {
        const res = await this.$http.post(COUPON_GENERATE, {
          cupon: this.cupon,
          userID: this.buyer.id
        })
        this.generateCupon = true
        this.cuponCode = res.data
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Se genero cupon'
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    closeCupon() {
      this.dialogCoupon = false
      setTimeout(() => {
        this.generateCupon = false
      }, 800)
    }
  }
}
</script>

<style>
strong {
  font-weight: 600;
}
</style>
