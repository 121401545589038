<template>
  <v-content sticky-container>
    <v-container grid-list-lg fluid>
      <v-layout v-if="active" row wrap fill-height>
        <BuyerNav :buyer="buyer" :go-route="goRoute"></BuyerNav>
        <v-flex xs12 lg9 sm9 md9>
          <router-view :buyer="buyer" :get-buyer="getBuyer" />
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import { CLIENT } from '../../config'
import BuyerNav from './nav.vue'

export default {
  name: 'Buyer',
  components: { BuyerNav },
  props: ['id'],
  data: () => ({
    active: false,
    buyer: {}
  }),
  watch: {
    id() {
      this.getBuyer()
    }
  },
  mounted() {
    this.getBuyer()
  },
  methods: {
    async getBuyer() {
      try {
        const res = await this.$http.get(`${CLIENT}/${this.id}`)
        this.active = res.data.active
        this.buyer = res.data.user
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    goRoute(item) {
      this.$router.push({ name: item.route })
    }
  }
}
</script>

<style></style>
