<template>
  <div>
    <div
      v-if="type === 0 && ordersLastYear && lastPurchase"
      :class="`${addClass} ${cologbg}`"
      style="text-decoration: none; border-radius: 10px;"
    >
      <p class="caption mb-0">{{ `Compras al año: ${ordersLastYear}` }}</p>
      <p class="caption mb-0">Ultima compra el {{ lastPurchase | moment('DD-MM-YYYY') }}</p>
    </div>
    <router-link
      v-if="type === 1 && idCustomer"
      :class="`${addClass} ${cologbg}`"
      target="_blank"
      :to="{ name: 'buyerDetail', params: { id: idCustomer } }"
      style="display: flex;text-decoration: none; padding: 6px 4px; border-radius: 10px;"
    >
      <v-icon class="white--text mx-2">person</v-icon>
      <div v-if="ordersLastYear && lastPurchase" class="white--text mx-2 text-xs-left">
        <p class="caption mb-0" v-html="`Compras al año: ${ordersLastYear}`"></p>
        <p class="caption mb-0">Ultima compra el {{ lastPurchase | moment('DD-MM-YYYY') }}</p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'AvatarLetter',
  props: {
    lastPurchase: {
      type: String,
      default: ''
    },
    ordersLastYear: {
      type: Number,
      default: 0
    },
    type: {
      type: Number,
      default: 0
    },
    idCustomer: {
      type: Number,
      default: 0
    },
    addClass: {
      type: String,
      default: 'px-2 py-2 mb-2'
    }
  },
  computed: {
    cologbg() {
      const { ordersLastYear } = this
      if (ordersLastYear === 1) {
        return 'grey black--text'
      } else if (ordersLastYear === 2) {
        return 'info black--text'
      } else if (ordersLastYear >= 3) {
        return 'warning black--text'
      }
      return ''
    }
  }
}
</script>
